import clsx from 'clsx'
import { SubscribeModal } from './SubscribeModal'
import { FaceSmileIcon } from '@heroicons/react/16/solid'

export function AppStoreLink({
  color = 'black',
}: {
  color?: 'black' | 'white'
}) {
  return (
    <SubscribeModal autoOpen={false}>
    <a
      href="#"
      aria-label="Download on the App Store"
      
    >
            <img src={process.env.PUBLIC_URL + '/images/app-store-badge.png'} alt="Play Store" className="h-10" />

    </a>
    </SubscribeModal>
  )
}
