import { Disclosure } from "@headlessui/react";
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';

const faqs = [
    
      {
        question: 'Why I can\'t bid for a specific value?',
        answer:
          'Astone is made to be a fun and easy way to bid. We want to make bidding as fast and funny as possible then next bid value is automatically proposed and the used need only to press the bid button.',
      },
      {
        question: 'Who define the bid value?',
        answer:
          'Bid value is set by the seller.',
      },
      {
        question: 'When auction ends?',
        answer:
          'The auction concludes after a period without new bids. Astone manages the process. If there\'s a lapse in bidding activity, Astone prompts for new bids. When no further bids are made, the auction is closed, and the participant with the highest bid emerges as the winner.',
      },
  
      {
        question: 'Does placing bids in an auction represent a commitment to purchase?',
        answer:
          'Yes, placing bids in an auction constitutes a commitment between the bidder and the seller. The agreement is finalized when the winning bidder pays for the item, and the seller ships it accordingly. However, if the winning bidder fails to make the payment, the seller refrains from shipping the item. In such cases, the bidder\'s Astone team reserves the right to take appropriate action.',
      },
      {
        question: 'What is the cost for the seller?',
        answer:
          'Astone is free for the seller. The seller can set the bid value and the auction duration. Astone will take a fee only if the auction is won.',
      },
      {
        question: 'Is bidding free?',
        answer:
          'Yes, bidding is free. You can bid as many times as you want without any cost. You will be charged only if you win the auction.',
      },
   
      {
        question: 'What is the bid delay?',
        answer:
          'The bid delay refers to the time between successive bids. Astone manages the bid delay, and bidders have the option to purchase the "bid booster" to bypass this delay.',
      },
      {
        question: 'Are there any restrictions on items I can sell?',
        answer:
          'Yes, there are some restrictions on the items you can sell. You can\'t sell items that are illegal, stolen, or counterfeit. You can\'t sell items that are restricted by law, such as prescription drugs. Depending on the category of the item, you may be required to provide additional information.',
      },
      {
        question: 'What if there is no the right category for my item?',
        answer:
          'If you can\'t find the right category for your item, you can contact us and we will add it.',
      },
   
  ]
  
export function FaqsAccordion() {
    return (
        <div className="bg-white">
          <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
            <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
              <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
              <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt>
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                            <span className="text-base font-semibold leading-7">{faq.question}</span>
                            <span className="ml-6 flex h-7 items-center">
                              {open ? (
                                <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              ) : (
                                <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                              )}
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </div>
      );
    }
