import { API_BASE_URL } from "../config";
import axios from "axios";

export function subscribeWaitlist(emailAddress: string) {
  return axios.post(`${API_BASE_URL}/landing/waitlist`, {
    emailAddress: emailAddress
  });
}


export function requestDeleteAccount(emailAddress: string) {
  return axios.post(`${API_BASE_URL}/landing/delete_account`, {
    emailAddress: emailAddress
  });
}

export function confirmDeleteAccount(reqId: string) {
  return axios.get(`${API_BASE_URL}/landing/confirm_delete_account/${reqId}`);
}