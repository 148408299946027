import { TextField } from "../components/Fields";
import { Layout } from "../components/Layout";
import React, { useState } from "react";
import { isValidEmail } from "../services/validation";
import { requestDeleteAccount } from "../services/api_functions";
import { Button } from "../components/Button";

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidEmail(email)) {
      await requestDeleteAccount(email);
      setIsSubscribed(true);
    } else {
      // handle invalid email
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Layout showHeader={false} showFooter={false}>
        <div className="flex flex-col items-center  pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          {!isSubscribed ? (
            <form
              className="flex flex-col items-center justify-center w-full md:w-auto"
              onSubmit={handleSubmit}
              style={{ margin: "auto" }}
            >
                  <p className="mb-4 text-center">Type below your email, a request to delete the associated account will be created.</p>

              <TextField
                type="email"
                aria-label="Email address"
                placeholder="Email address"
                autoComplete="email"
                required
                className="w-60 min-w-0 shrink mb-4"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button type="submit" color="gray" className="flex-none">
                <span className="hidden lg:inline">Delete account</span>
                <span className="lg:hidden">Delete account</span>
              </Button>
            </form>
          ) : (
            <div className="flex flex-col items-center justify-center h-full w-full text-center">
            <p className="text-gray-500 ">
              Your request has been received. Please check your email for
              further instructions.
            </p>
          </div>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default DeleteAccount;
