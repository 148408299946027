export function Logomark(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <img
      src={process.env.PUBLIC_URL + "/logo192.png"}
      className="mx-auto max-w-[40px]"
      alt="logo"
    />
  );
}

export function Logo(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <img
      src={process.env.PUBLIC_URL + "/logo192.png"}
      className="mx-auto max-h-[80px]"
      alt="logo"
    />
  );
}
