import { Helmet } from "react-helmet";

export function OGMetas() {
  return (
    <div>
      <Helmet>
        <meta property="og:url" content="https://www.astone.one" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Astone - The Realitme Auctions App"
        />
        <meta
          property="og:description"
          content="It's time to overcome the boring old auctions and start bidding like a pro!"
        />
        <meta
          property="og:image"
          content={process.env.PUBLIC_URL + "/images/auctions-screen.png"}
        />
      </Helmet>
    </div>
  );
}
