import { Footer } from './Footer'
import { Header } from './Header'

export function Layout({ children, showHeader=true, showFooter=true }: { children: React.ReactNode, showHeader?: boolean, showFooter?: boolean}) {
  return (
    <>
      <Header showHeader={showHeader} />
      <main className="flex-auto flex flex-col justify-center">{children}</main>
      <Footer showFooter={showFooter} />
    </>
  )
}
