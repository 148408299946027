import { CallToAction } from "./components/CallToAction";
import { Faqs } from "./components/Faqs";
import { Hero } from "./components/Hero";
import { Layout } from "./components/Layout";
import { PrimaryFeatures } from "./components/PrimaryFeatures";
import { SecondaryFeatures } from "./components/SecondaryFeatures";
import CookieConsent from "react-cookie-consent";

import "./styles/tailwind.css";
import { TimedSubscribeModal } from "./components/SubscribeModal";
import { OGMetas } from "./components/OGMetas";
import { FaqsAccordion } from "./components/Faqs_accordion";

function App() {
  return (
    <Layout>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="astoneCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        I hereby give my consent to receive commercial communications pursuant
        to Articles 13-14 of the European Regulation 2016/679 (GDPR).{" "}
      </CookieConsent>
      <OGMetas />
      <Hero />
      <TimedSubscribeModal />
      <PrimaryFeatures />
      <SecondaryFeatures />
      <CallToAction />
      {/*  <Faqs /> */}
      <FaqsAccordion />
    </Layout>
  );
}

export default App;
