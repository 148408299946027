import { useState } from "react";
import { Button } from "./Button";
import { Container } from "./Container";
import { TextField } from "./Fields";
import { Logomark } from "./Logo";
import { NavLinks } from "./NavLinks";
import { isValidEmail } from "../services/validation";
import { subscribeWaitlist } from "../services/api_functions";
import { AppStoreLink } from "./AppStoreLink";
import { PlayStoreLink } from "./PlayStoreLink";


export function Footer({ showFooter = true }: { showFooter?: boolean }) {
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isValidEmail(email)) {
      await subscribeWaitlist(email);
      setIsSubscribed(true);
      // Call the gtag function with the event action and parameters
      window.gtag("event", "submit_email", {
        event_category: "email",
        event_label: "submit",
        value: 1,
      });
    } else {
      // handle invalid email
    }
  };

  return (
    <footer className="border-t border-gray-200">
      <Container>
        {showFooter && (
          <div className="flex flex-col items-start justify-between gap-y-12 pb-6 pt-16 lg:flex-row lg:items-center lg:py-16">
            <div>
              <div className="flex items-center text-gray-900">
                <Logomark className="h-20 w-20 flex-none fill-cyan-500" />
                <div className="ml-4">
                  <p className="text-base font-semibold">Astone</p>
                </div>
              </div>
              <nav className="mt-11 flex gap-8">
                <NavLinks />
              </nav>
            </div>

            <div className="group relative -mx-4 flex items-center self-stretch p-4 transition-colors hover:bg-gray-100 sm:self-auto sm:rounded-2xl lg:mx-0 lg:self-auto lg:p-6">
              <div className="mr-4">
              <AppStoreLink  />
              </div>
              <PlayStoreLink />
            </div>
          </div>
        )}
        <div className="flex flex-col items-center border-t border-gray-200 pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          {!isSubscribed ? (
            <form
              className="flex w-full justify-center md:w-auto"
              onSubmit={handleSubmit}
            >
              <TextField
                type="email"
                aria-label="Email address"
                placeholder="Email address"
                autoComplete="email"
                required
                className="w-60 min-w-0 shrink"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button type="submit" color="cyan" className="ml-4 flex-none">
                <span className="hidden lg:inline">Join our waitlist</span>
                <span className="lg:hidden">Join our waitlist</span>
              </Button>
            </form>
          ) : (
            <p className="text-gray-500">
              Thanks for subscribing to our waitlist!
            </p>
          )}
          <p className="mt-6 text-sm text-gray-500 md:mt-0">
            Astone, bidding like chatting.
          </p>
          {/*
          <p className="mt-6 text-sm text-gray-500 md:mt-0">
            &copy; Copyright {new Date().getFullYear()} by{" "}
            <a href="https://www.little-endian.it">Little Endian</a>. All rights
            reserved.
        </p> */}
        </div>
      </Container>
    </footer>
  );
}
