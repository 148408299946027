import clsx from "clsx";
import { SubscribeModal } from "./SubscribeModal";

export function PlayStoreLink({
  color = "black",
}: {
  color?: "black" | "white";
}) {
  return (
    <a href="#" aria-label="Download on the Play Store">
      <SubscribeModal autoOpen={false}>
        <img
          src={process.env.PUBLIC_URL + "/images/google-play-badge.png"}
          alt="Play Store"
          className="h-10"
        />
      </SubscribeModal>
    </a>
  );
}
