import { Layout } from "../components/Layout";
import { useEffect, useState } from "react";
import { confirmDeleteAccount } from "../services/api_functions";
import { useParams } from "react-router-dom";

const ConfirmDeleteAccount = () => {
  let { reqId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (reqId) {
      setIsLoading(true);
      confirmDeleteAccount(reqId).finally(() => setIsLoading(false));
    }
  }, [reqId]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div
          className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        >
          <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
            Loading...
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <Layout showHeader={false} showFooter={false}>
        <div className="flex flex-col items-center  pb-12 pt-8 md:flex-row-reverse md:justify-between md:pt-6">
          <div className="flex flex-col items-center justify-center h-full w-full text-center">
            <p className="text-gray-500 ">
              Your account has been deleted. We are sorry to see you go :-(
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ConfirmDeleteAccount;
