import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { subscribeWaitlist } from "../services/api_functions";
import { isValidEmail } from "../services/validation";
import { ReactComponent as WaitingList } from "./images/waiting-list.svg";

Modal.setAppElement("#root"); // This line is needed for accessibility reasons

export function SubscribeModal({
  children,
  autoOpen = false,
}: {
  children: React.ReactNode;
  autoOpen: boolean;
}) {
  const [modalIsOpen, setModalIsOpen] = useState(autoOpen);
  const [email, setEmail] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isSubscribed) {
      timer = setTimeout(() => {
        closeModal();
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [isSubscribed]);

  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    if (modalIsOpen) return;
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setIsSubscribed(false);
    setEmail("");
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      subscribeWaitlist(email);

      setIsSubscribed(true);

      // Call the gtag function with the event action and parameters
      window.gtag("event", "submit_email", {
        event_category: "email",
        event_label: "submit",
        value: 1,
      });
    } catch (error) {
      console.error("Error subscribing to waitlist", error);
    }
  };

  return (
    <div>
      <button onClick={openModal}>{children}</button>

      <Modal
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeModal}
        contentLabel="Subscribe to Waitlist"
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 "
        style={{ overlay: { zIndex: 2000 } }}
      >
        <div className="relative bg-white rounded-lg w-1/2 max-w-lg p-5 m-auto ">
          <button
            onClick={closeModal}
            className="absolute top-0 right-0 m-2 text-gray-500 hover:text-gray-700 bg-white border border-black rounded-full h-8 w-8 flex items-center justify-center"
          >
            X
          </button>
          {isSubscribed ? (
            <p>Thanks for subscribing to our waitlist!</p>
          ) : (
            <div className="text-center mb-4 text-gray-700">
              <img
                src={`${process.env.PUBLIC_URL}/images/waiting-list.svg`}
                alt="Waiting List"
                className="h-20 mx-auto block mb-4"
              />
              <strong className="block text-3xl font-bold text-center mb-2 text-gray-700 ">
                Join waitlist
              </strong>

              <p className="mb-4">
                Subscribe to our waitlist to be notified when Astone will be
                available.
              </p>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full px-5 py-3 my-2 border rounded border-gray-300 box-border"
                />
                <button
                  type="submit"
                  disabled={!isValidEmail(email)}
                  className="bg-cyan-500 rounded-lg text-white px-5 py-2 my-1 text-center cursor-pointer"
                >
                  Join the Waitlist
                </button>
              </form>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export function TimedSubscribeModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalIsOpen(true);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      {modalIsOpen && (
        <SubscribeModal autoOpen={modalIsOpen}>{""}</SubscribeModal>
      )}
    </>
  );
}
